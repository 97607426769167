import React from 'react';
import { graphql } from 'gatsby';

import { CareersPageTemplate } from '../templates';
import { Layout } from '../components';

const CareersPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const careers = data.allMarkdownRemark.edges.map(({ node }) => node);
    return (
        <Layout title={ frontmatter.headerTitle }>
            <CareersPageTemplate { ...frontmatter} careers={ careers }/>
        </Layout>
    );
};

export default CareersPage;

export const pageQuery = graphql`
    query CareersPageQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "careers-page" } }) {
            frontmatter {
                headerTitle
                headerImage {
                    assetType
                    cloudinaryMedia
                    deliveryType
                    sourceURL
                    urlRemainder
                }
                heroTitle
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "career" }, visible: { eq: true } } },
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    fileAbsolutePath
                    frontmatter {
                        title
                        date
                        jobSpec
                    }
                }
            }
        }
    }
`;